body {
  background-color: black;
}
.containerBig {
  height: 100vh;
  flex-direction: column;
  display: flex;
  justify-content: center;
  justify-content: center;
  align-self: center;

}
.button {
  background-color: #05005e;
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}

.square {
  position: relative;
}
.icon {
  fill : white;
}
.muteIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 10px; top: 20px;
}

.pinIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 50px; top: 20px;
}
.blackEl {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 250px; top: 20px;
}
.nametag {
  padding-top: 10px;
  height: 30px;
  border-radius: 30px;
  background-color: black;
  color: white;
  position: absolute; left: 10px; bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.subVideoEl {
  display: inline-block;
  position: relative;
  max-width: 100%; height: 100%;
  overflow: hidden;
  flex-direction: column;
}

.jamkazamOuterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flipHorizontal
{
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
}
.flipVertical
{
  transform: rotateX(180deg);
  -webkit-transform:rotateX(180deg); /* Safari and Chrome */
  -moz-transform:rotateX(180deg); /* Firefox */
}
.flipHorizontalAndVertical {
  transform: rotateX(180deg) rotateY(180deg);
  -webkit-transform:rotateX(180deg) rotateY(180deg); /* Safari and Chrome */
  -moz-transform:rotateX(180deg) rotateY(180deg); /* Firefox */
}

.grayscale {
  filter: grayscale(100%); 
}

.bright-2 {
  filter:brightness(125%);
}
.bright-2grayscale {
  filter: brightness(125%) grayscale(100%);
}

.bright-3 {
  filter:brightness(155%);
}

.bright-3grayscale {
  filter:brightness(155%) grayscale(100%);
}

.bright-4 {
  filter:brightness(195%);
}

.bright-4grayscale {
  filter:brightness(195%) grayscale(100%);
}

.verticalIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 130px; top: 20px;  
}

.horizontalIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 170px; top: 20px;
}

.videoOffIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 210px; top: 20px;
}

.soloEl {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 90px; top: 20px;
}

.aspectRatioSmallIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 130px; top: 70px;
}

.aspectRatioSquareIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 170px; top: 70px;

}
.smallResIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 10px; top: 70px;

}
.midResIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 50px; top: 70px;

}
.largeResIcon {
  padding-top: 5px;
  width: 35px;
  height: 30px;
  border-radius: 30px;
  background-color: white;
  position: absolute; right: 90px; top: 70px;

}

.videoElement {
  min-width: 400px;
  min-width: 400px;
}